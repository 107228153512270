import React from 'react';
import './Content.css';
import ParticleHeaderBg from '../ParticlesBg/ParticlesHeader/ParticleHeaderBg';

/* ReactScroll */
import { Link } from 'react-scroll';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

const Content = () => (
    <div className="contenido">
        <ParticleHeaderBg/>
        <section className="inicio" id="inicio">
        
            <div className="titulo">
            
                <p data-aos="fade-up" data-aos-delay="600">
                    <FormattedMessage
                        id='greeting'
                        defaultMessage='Hello'
                    />
                </p>
                <h1 data-aos="fade-up" data-aos-delay="800">
                    <FormattedMessage
                        id='name'
                        defaultMessage="we are Amara Tech"
                    />
                </h1>
                <p data-aos="fade-up" data-aos-delay="1000">
                    <FormattedMessage
                        id='rol'
                        defaultMessage="transform your dreams into the digital world"
                    />
                </p>
                <div style={{textAlign: 'center'}}>
                    <div className="redes-sociales">
                        
                        <a href="https://www.instagram.com/amaratechh/" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1600">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=201126497407&text=Hello%20I%20am%20interested%20in%20your%20services" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1800">
                            <i className="fab fa-whatsapp"></i>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61552207981779&mibextid=JRoKGi" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1400">
                            <i className="fab fa-facebook"></i>
                        </a>
                    </div>
                   <div className="wrapper">
                       
                       <a className="button" href="https://www.instagram.com/amaratechh/" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1600">
                           <div className="icon">
                               <i className="fab fa-instagram"></i>
                           </div>
                           <span>Instagram</span>
                       </a>
                       <a className="button" href="https://api.whatsapp.com/send?phone=201126497407&text=Hello%20I%20am%20interested%20in%20your%20services"target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1800">
                           <div className="icon">
                               <i className="fab fa-whatsapp"></i>
                           </div>
                           <span>WhatsApp</span>
                       </a>
                       <a className="button" href="https://www.facebook.com/profile.php?id=61552207981779&mibextid=JRoKGi" target="_blank" rel="noopener noreferrer" data-aos="fade-up" data-aos-delay="1400">
                           <div className="icon">
                               <i className="fab fa-facebook"></i>
                           </div>
                           <span>Facebook</span>
                       </a>
                   </div>
               </div>


                <Link to="sobre-mi" href="#sobre-mi">
                    <div className="scroll-down"></div>
                </Link>
            </div>
        </section>
    </div>
);

export default Content;