import React from 'react';
import './AboutPage.css'

/* Componet */
import HeaderPage from '../../components/Header/HeaderPage';
import Footer from '../../components/Footer/Footer';
import ParticleBackground from "../../components/ParticlesBg/ParticleBackground";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';


/* Multi idioma */
import { FormattedMessage, useIntl } from 'react-intl';



const About = () => {

  function readMore() {
    let btnHide = document.querySelector("#btn-hide");
    let parrafoActive = document.querySelector(".parrafo-active");

    parrafoActive.classList.toggle("show");

    if (parrafoActive.classList.contains("show")) {
      btnHide.innerHTML = "↑";
    } else {
      btnHide.innerHTML = "Read more";
    }
  }

  return (
    <div>

      <HeaderPage />

      <ParticleBackground />

      <main>
        <section className="sobre-mi-seccion" id="sobre-mi">
          <div className=" sobre-mi-info">
              <p>
                <FormattedMessage
                  id='about-info-1'
                  defaultMessage="Welcome to amara tech, where innovation meets excellence. Our mission is to provide cutting-edge technology solutions tailored to the unique needs of businesses around the globe. With a passion for transforming ideas into reality, we specialize in mobile app development, web development, and software customization, ensuring that our clients stay ahead in the fast-paced digital world."                />
              </p>

              <div className="hide parrafo-active">
                <p>
                  <FormattedMessage
                    id='about-info-2'
                    defaultMessage=" At amara tech, we believe that every business deserves the best tools to succeed. That's why our team of experts is dedicated to delivering high-quality, reliable, and scalable solutions that empower businesses to grow and thrive. From concept to completion, we work closely with our clients to understand their goals and deliver solutions that not only meet but exceed their expectations."
                  />
                </p>

                <p>
                  <FormattedMessage
                    id='about-info-3'
                    defaultMessage="Our commitment to excellence doesn't stop at development. We offer ongoing support and maintenance to ensure that your technology continues to perform at its best. Whether you're a small startup or a large enterprise, amara tech is here to help you navigate the complexities of the digital world and achieve your business objectives. Let's work together to build the future."                  />
                </p>
              </div>

              <div className="btn-info">
                <div className="custom-btn btn-codigo" id="btn-hide" onClick={readMore}><span>Read more</span></div>
              </div>
            

          </div>

          <div className="skill-section">
    <h1 className="heading">Skills</h1>
    <div className="skill-container">
        <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
            <img alt="WordPress" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/wordpress/wordpress-original.svg" title="WordPress" />
            <h2 className="skill-name">WordPress</h2>
            <p className="skill-info">
                <FormattedMessage
                    id='about-skills-1'
                    defaultMessage='A powerful content management system (CMS) that I utilize to create and manage dynamic websites. With extensive experience, I ensure seamless user experiences and custom solutions.'
                />
            </p>
        </div>
        <div className="skill-card" data-aos="flip-up" data-aos-delay="300">
            <img alt="Magento" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/magento/magento-original.svg" title="Magento" />
            <h2 className="skill-name">Magento</h2>
            <p className="skill-info">
                <FormattedMessage
                    id='about-skills-2'
                    defaultMessage="A leading e-commerce platform that I've worked with for developing robust online stores. My experience includes implementing custom features and optimizing performance for enhanced user engagement."
                />
            </p>
        </div>
        <div className="skill-card" data-aos="flip-right" data-aos-delay="300">
            <img alt="phpBB" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg" title="phpBB" />
            <h2 className="skill-name">phpBB</h2>
            <p className="skill-info">
                <FormattedMessage
                    id='about-skills-12'
                    defaultMessage="An open-source forum software that I've integrated into projects, allowing for community building and user interaction. I leverage its features to create engaging online discussions."
                />
            </p>
        </div>
        <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
            <img alt="FlutterFlow" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg" title="FlutterFlow" />
            <h2 className="skill-name">FlutterFlow</h2>
            <p className="skill-info">
                <FormattedMessage
                    id='about-skills-13'
                    defaultMessage="A visual development tool for building mobile applications that I've employed to streamline app creation. It allows me to quickly prototype and iterate on user interfaces."
                />
            </p>
        </div>
        <div className="skill-card" data-aos="flip-down" data-aos-delay="300">
            <img alt="Flutter Canvas" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/canva/canva-original.svg" title="Flutter Canvas" />
            <h2 className="skill-name">Flutter Canvas</h2>
            <p className="skill-info">
                <FormattedMessage
                    id='about-skills-14'
                    defaultMessage="Part of the Flutter framework, I use the Canvas API to create custom graphics and animations in my mobile applications, enhancing user experiences with rich visuals."
                />
            </p>
        </div>
        <div className="skill-card" data-aos="flip-right" data-aos-delay="300">
            <img alt="Firebase" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg" title="Firebase" />
            <h2 className="skill-name">Firebase</h2>
            <p className="skill-info">
                <FormattedMessage
                    id='about-skills-15'
                    defaultMessage="A comprehensive platform for building web and mobile apps, allowing me to integrate real-time databases and authentication seamlessly into my projects for improved user engagement."
                />
            </p>
        </div>
        <div className="skill-card" data-aos="flip-right" data-aos-delay="300">
            <img alt="C#" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg"title="C#" />
            <h2 className="skill-name">C#</h2>
            <p className="skill-info">
                <FormattedMessage
                    id='about-skills-16'
                    defaultMessage="A versatile programming language I've used for developing desktop and web applications. My expertise allows me to create efficient and maintainable code solutions."
                />
            </p>
        </div>
        <div className="skill-card" data-aos="flip-left" data-aos-delay="300">
            <img alt="SQLite" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sqlite/sqlite-original.svg" title="SQLite" />
            <h2 className="skill-name">SQLite</h2>
            <p className="skill-info">
                <FormattedMessage
                    id='about-skills-17'
                    defaultMessage="A lightweight database engine that I often use for mobile and desktop applications, enabling efficient data storage and retrieval with minimal setup."
                />
            </p>
        </div>
        <div className="skill-card" data-aos="flip-up" data-aos-delay="300">
            <img alt="MySQL" className="skills-img" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg" title="MySQL" />
            <h2 className="skill-name">MySQL</h2>
            <p className="skill-info">
                <FormattedMessage
                    id='about-skills-18'
                    defaultMessage="A widely used relational database management system that I leverage to design scalable data architectures for web applications, ensuring reliable data handling and integrity."
                />
            </p>
        </div>
    </div>
</div>

        </section>
      </main>

      <ScrollToTop />

      <Footer />

    </div>
  )
}
export default About;