import React from 'react';
import '../../pages/Contact/ContactPage.css'
/* TypedJs */
import Typical from 'react-typical';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

const Contact = () => (

    <section className="contactos" id="contactos">

        <h2 className="heading">
            <FormattedMessage
                id='contact'
                defaultMessage='Contact'
            />
        </h2>
        <h3 className="titulo" data-aos="fade-left" data-aos-delay="300" >
            <FormattedMessage
                id='contact-info'
                defaultMessage='Contact us by: '
            />
            <Typical
                className="site-contacto"
                loop={Infinity}
                wrapper="b"
                steps={[
                    'Gmail', 1500,
                    'WhatsApp', 1500,
                    'Instragram', 1500,
                    'Facebook', 1500,
                ]}
            />

        </h3>

        <div className="icons">
            <a href="mailto:amara tech@outlook.com" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span className="fab fas fa-envelope"></span>
                </div>
                <div className="text">
                    Gmail
                </div>
            </a>
            <a href="https://api.whatsapp.com/send?phone=201126497407&text=Hello%20I%20am%20interested%20in%20your%20services"target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span className="fab fa-whatsapp"></span>
                </div>
                <div className="text">
                    Whatsapp
                </div>
            </a>
            <a href="https://www.instagram.com/amaratechh/" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span className="fab fa-instagram"></span>
                </div>
                <div className="text">
                    Instagram
                </div>
            </a>
            <a href="https://web.facebook.com/profile.php?id=61552207981779" target="_blank" rel="noopener noreferrer" data-aos="zoom-in">
                <div className="layer">
                   <span></span>
                   <span></span>
                   <span></span>
                   <span></span>
                   <span className="fab fa-facebook"></span>
                </div>
                <div className="text">
                    Facebook
                </div>
             </a>
            
            
        </div>
    </section>
);

export default React.memo(Contact);