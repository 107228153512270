import React from 'react';
import './ServicesPage.css'

/* Componet */
import HeaderPage from '../../components/Header/HeaderPage';
import Footer from '../../components/Footer/Footer';
import ParticleBackground from "../../components/ParticlesBg/ParticleBackground";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import Accordion from './Accordion'


/* Multi idioma */
import { FormattedMessage } from 'react-intl';

const Services = () => {
  return (
    <div>

      <HeaderPage />

      <ParticleBackground />

      <main className="service-page">
      <section className="servicios" id="servicios">
        <h2 className="heading">
            <FormattedMessage
                id='services'
                defaultMessage='Services'
            />
        </h2>
        <div className="row">
           <div className="columns" data-aos="fade-up" data-aos-delay="200">
        <i className="fas fa-drafting-compass"></i>
        <h3>
            <FormattedMessage
                id='ui-ux'
                defaultMessage='UI/UX Design'
            />
        </h3>
        <p>
            <FormattedMessage
                id='ui-ux-info'
                defaultMessage='Design of intuitive and visually appealing interfaces for both web and mobile users, ensuring a seamless user experience that enhances the brand or product.'
            />
        </p>
           </div>
           <div className="columns" data-aos="fade-up" data-aos-delay="300">
               <i className="fas fa-laptop"></i>
               <h3>
                   <FormattedMessage
                       id='web-development'
                       defaultMessage='Web Development'
                   />
               </h3>
               <p>
                   <FormattedMessage
                       id='web-development-info'
                       defaultMessage='Creation of well-structured and responsive web pages with an attractive design, offering interactions that provide users with a satisfying browsing experience.'
                   />
               </p>
           </div>
           <div className="columns" data-aos="fade-up" data-aos-delay="400">
               <i className="fas fa-mobile-alt"></i>
               <h3>
                   <FormattedMessage
                       id='mobile-app'
                       defaultMessage='Mobile App Development'
                   />
               </h3>
               <p>
                   <FormattedMessage
                       id='mobile-app-info'
                       defaultMessage='Development of user-friendly mobile applications with responsive design and efficient functionality tailored to enhance the mobile user experience.'
                   />
               </p>
           </div>
           <div className="columns" data-aos="fade-up" data-aos-delay="500">
               <i className="fas fa-cogs"></i>
               <h3>
                   <FormattedMessage
                       id='software-customization'
                       defaultMessage='Software Customization'
                   />
               </h3>
               <p>
                   <FormattedMessage
                       id='software-customization-info'
                       defaultMessage='Tailored software solutions to meet the unique needs of your business, ensuring seamless integration with existing systems and enhanced productivity.'
                   />
               </p>
           </div>
           <div className="columns" data-aos="fade-up" data-aos-delay="600">
               <i className="fas fa-brain"></i>
               <h3>
                   <FormattedMessage
                       id='ai-machine-learning'
                       defaultMessage='AI & Machine Learning'
                   />
               </h3>
               <p>
                   <FormattedMessage
                       id='ai-machine-learning-info'
                       defaultMessage='Integration of AI and machine learning technologies to drive innovation, automate processes, and provide data-driven insights for better decision-making.'
                   />
               </p>
           </div>
           <div className="columns" data-aos="fade-up" data-aos-delay="700">
               <i className="fas fa-shield-alt"></i>
               <h3>
                   <FormattedMessage
                       id='security'
                       defaultMessage='Security Solutions'
                   />
               </h3>
               <p>
                   <FormattedMessage
                       id='security-info'
                       defaultMessage='Comprehensive security solutions to protect your digital assets, including data encryption, vulnerability assessments, and secure software development practices.'
                   />
               </p>
           </div>
       </div>
    </section>
    <section className="site-services">
    <h2 className="heading">
        <FormattedMessage
            id='services-price'
            defaultMessage='Pricing Packages'
        />
    </h2>

    {/* Web Packages */}
    <h3 className='header-package'>
            <FormattedMessage
                id='web-packages-header'
                defaultMessage='Web Development Packages'
            />
        </h3>
    <div className="row">


        <div className="columns" data-aos="fade-up" data-aos-delay="200">
            <h3>
                <FormattedMessage
                    id='web-basic'
                    defaultMessage='Basic'
                />
            </h3>
            <h4 className="sub-title">
                <FormattedMessage
                    id='web-basic-subtitle'
                    defaultMessage='Ideal for small websites'
                />
            </h4>
            <p className="numero">
                <FormattedMessage
                    id='web-basic-price'
                    defaultMessage='EGP 4500'
                />
            </p>
            <ul className="ul-cards-services">
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-basic-feature-1'
                        defaultMessage='1 responsive page'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-basic-feature-2'
                        defaultMessage='2 sections per page'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-basic-feature-3'
                        defaultMessage='Contact Form'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-basic-feature-4'
                        defaultMessage='Domain for 1 year'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-basic-feature-5'
                        defaultMessage='Hosting for 1 year'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-basic-feature-6'
                        defaultMessage='Delivered in 7 business days'
                    />
                </li>
            </ul>
        </div>

        <div className="columns recomendado" data-aos="fade-up" data-aos-delay="300">
            <h3>
                <FormattedMessage
                    id='web-standard'
                    defaultMessage='Standard'
                />
            </h3>
            <h4 className="sub-title">
                <FormattedMessage
                    id='web-standard-subtitle'
                    defaultMessage='Great for medium-sized businesses'
                />
            </h4>
            <p className="numero">
                <FormattedMessage
                    id='web-standard-price'
                    defaultMessage='EGP 10,000'
                />
            </p>
            <ul className="ul-cards-services">
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-standard-feature-1'
                        defaultMessage='5 responsive pages'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-standard-feature-2'
                        defaultMessage='+3 sections per page'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-standard-feature-3'
                        defaultMessage='Contact Form'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-standard-feature-4'
                        defaultMessage='Domain for 1 year'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-standard-feature-5'
                        defaultMessage='Hosting for 1 year'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-standard-feature-6'
                        defaultMessage='Animations'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-standard-feature-7'
                        defaultMessage='Free maintenance for 6 months'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-standard-feature-8'
                        defaultMessage='Delivered in 14 business days'
                    />
                </li>
            </ul>
        </div>

        <div className="columns" data-aos="fade-up" data-aos-delay="400">
            <h3>
                <FormattedMessage
                    id='web-premium'
                    defaultMessage='Premium'
                />
            </h3>
            <h4 className="sub-title">
                <FormattedMessage
                    id='web-premium-subtitle'
                    defaultMessage='Ideal for large-scale websites'
                />
            </h4>
            <p className="numero">
                <FormattedMessage
                    id='web-premium-price'
                    defaultMessage='EGP 15,000'
                />
            </p>
            <ul className="ul-cards-services">
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-premium-feature-1'
                        defaultMessage='10 responsive pages'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-premium-feature-2'
                        defaultMessage='+3 sections per page'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-premium-feature-3'
                        defaultMessage='Contact Form'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-premium-feature-4'
                        defaultMessage='Domain for 1 year'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-premium-feature-5'
                        defaultMessage='Hosting for 1 year'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-premium-feature-6'
                        defaultMessage='Advanced animations'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-premium-feature-7'
                        defaultMessage='Free maintenance for 1 year'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='web-premium-feature-8'
                        defaultMessage='Delivered in 21 business days'
                    />
                </li>
            </ul>
        </div>
    </div>

    {/* Mobile App Packages */}
    <h3 className='header-package'>
            <FormattedMessage
                id='mobile-packages-header'
                defaultMessage='Mobile App Development Packages'
            />
        </h3>
    <div className="row">


        <div className="columns" data-aos="fade-up" data-aos-delay="200">
            <h3>
                <FormattedMessage
                    id='mobile-basic'
                    defaultMessage='Basic'
                />
            </h3>
            <h4 className="sub-title">
                <FormattedMessage
                    id='mobile-basic-subtitle'
                    defaultMessage='Perfect for simple apps'
                />
            </h4>
            <p className="numero">
                <FormattedMessage
                    id='mobile-basic-price'
                    defaultMessage='EGP 10,000'
                />
            </p>
            <ul className="ul-cards-services">
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-basic-feature-1'
                        defaultMessage='2 platforms (iOS and Android)'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-basic-feature-2'
                        defaultMessage='Custom UI/UX design'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-basic-feature-3'
                        defaultMessage='3 core features'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-basic-feature-4'
                        defaultMessage='Free maintenance for 6 months'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-basic-feature-5'
                        defaultMessage='Delivered in 20 business days'
                    />
                </li>
            </ul>
        </div>

        <div className="columns recomendado" data-aos="fade-up" data-aos-delay="300">
            <h3>
                <FormattedMessage
                    id='mobile-standard'
                    defaultMessage='Standard'
                />
            </h3>
            <h4 className="sub-title">
            <FormattedMessage
                    id='mobile-standard-subtitle'
                    defaultMessage='Great for feature-rich apps'
                />
            </h4>
            <p className="numero">
                <FormattedMessage
                    id='mobile-standard-price'
                    defaultMessage='EGP 20,000'
                />
            </p>
            <ul className="ul-cards-services">
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-standard-feature-1'
                        defaultMessage='3 platforms (iOS, Android, Web)'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-standard-feature-2'
                        defaultMessage='Advanced UI/UX design'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-standard-feature-3'
                        defaultMessage='6 core features'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-standard-feature-4'
                        defaultMessage='API integrations'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-standard-feature-5'
                        defaultMessage='Free maintenance for 12 months'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-standard-feature-6'
                        defaultMessage='Delivered in 30 business days'
                    />
                </li>
            </ul>
        </div>

        <div className="columns" data-aos="fade-up" data-aos-delay="400">
            <h3>
                <FormattedMessage
                    id='mobile-premium'
                    defaultMessage='Premium'
                />
            </h3>
            <h4 className="sub-title">
                <FormattedMessage
                    id='mobile-premium-subtitle'
                    defaultMessage='Ideal for complex applications'
                />
            </h4>
            <p className="numero">
                <FormattedMessage
                    id='mobile-premium-price'
                    defaultMessage='EGP 25,000'
                />
            </p>
            <ul className="ul-cards-services">
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-premium-feature-1'
                        defaultMessage='Unlimited platforms'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-premium-feature-2'
                        defaultMessage='Custom and interactive UI/UX design'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-premium-feature-3'
                        defaultMessage='Unlimited core features'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-premium-feature-4'
                        defaultMessage='Custom API integrations'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-premium-feature-5'
                        defaultMessage='Free maintenance for 18 months'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='mobile-premium-feature-6'
                        defaultMessage='Delivered in 45 business days'
                    />
                </li>
            </ul>
        </div>
    </div>

    {/* Software Customization Packages */}
    <h3 className='header-package'>
            <FormattedMessage
                id='software-packages-header'
                defaultMessage='Software Customization Packages'
            />
        </h3>
    <div className="row">


        <div className="columns" data-aos="fade-up" data-aos-delay="200">
            <h3>
                <FormattedMessage
                    id='software-basic'
                    defaultMessage='Basic'
                />
            </h3>
            <h4 className="sub-title">
                <FormattedMessage
                    id='software-basic-subtitle'
                    defaultMessage='Ideal for small software needs'
                />
            </h4>
            <p className="numero">
                <FormattedMessage
                    id='software-basic-price'
                    defaultMessage='EGP 5,000'
                />
            </p>
            <ul className="ul-cards-services">
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-basic-feature-1'
                        defaultMessage='1 custom feature'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-basic-feature-2'
                        defaultMessage='Basic UI/UX design'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-basic-feature-3'
                        defaultMessage='Basic functionality'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-basic-feature-4'
                        defaultMessage='Free maintenance for 3 months'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-basic-feature-5'
                        defaultMessage='Delivered in 15 business days'
                    />
                </li>
            </ul>
        </div>

        <div className="columns recomendado" data-aos="fade-up" data-aos-delay="300">
            <h3>
                <FormattedMessage
                    id='software-standard'
                    defaultMessage='Standard'
                />
            </h3>
            <h4 className="sub-title">
                <FormattedMessage
                    id='software-standard-subtitle'
                    defaultMessage='Perfect for mid-sized applications'
                />
            </h4>
            <p className="numero">
                <FormattedMessage
                    id='software-standard-price'
                    defaultMessage='EGP 10,000'
                />
            </p>
            <ul className="ul-cards-services">
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-standard-feature-1'
                        defaultMessage='3 custom features'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-standard-feature-2'
                        defaultMessage='Advanced UI/UX design'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-standard-feature-3'
                        defaultMessage='Integration with existing systems'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-standard-feature-4'
                        defaultMessage='Free maintenance for 6 months'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-standard-feature-5'
                        defaultMessage='Delivered in 30 business days'
                    />
                </li>
            </ul>
        </div>

        <div className="columns" data-aos="fade-up" data-aos-delay="400">
            <h3>
                <FormattedMessage
                    id='software-premium'
                    defaultMessage='Premium'
                />
            </h3>
            <h4 className="sub-title">
                <FormattedMessage
                    id='software-premium-subtitle'
                    defaultMessage='Ideal for complex and large-scale solutions'
                />
            </h4>
            <p className="numero">
                <FormattedMessage
                    id='software-premium-price'
                    defaultMessage='EGP 15,000'
                />
            </p>
            <ul className="ul-cards-services">
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-premium-feature-1'
                        defaultMessage='Unlimited custom features'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-premium-feature-2'
                        defaultMessage='Highly advanced UI/UX design'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-premium-feature-3'
                        defaultMessage='Custom API integrations'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-premium-feature-4'
                        defaultMessage='Full documentation and training'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-premium-feature-5'
                        defaultMessage='Free maintenance for 1 year'
                    />
                </li>
                <li>
                    <i className="fas fa-check"></i>
                    <FormattedMessage
                        id='software-premium-feature-6'
                        defaultMessage='Delivered in 60 business days'
                    />
                </li>
            </ul>
        </div>
    </div>
</section>


<section className="preguntas">
  <h2 className="heading">
    <FormattedMessage
      id='services-questions'
      defaultMessage='Frequent Questions'
    />
  </h2>
  <div className="accordion-container">
    <Accordion
      title={<FormattedMessage
        id='services-questions-1'
        defaultMessage='What is the importance of responsive design in web development?'
      />}
      content={<FormattedMessage
        id='services-questions-p1'
        defaultMessage='Responsive design ensures that a website looks and functions well on all devices, from mobile phones to desktops. It improves user experience and accessibility, and helps with SEO as search engines prioritize mobile-friendly sites.'
      />}
      dataAos="fade-right"
      dataAosDelay="300"
    />

    <Accordion
      title={<FormattedMessage
        id='services-questions-2'
        defaultMessage='What are the key features of a well-designed mobile app?'
      />}
      content={<FormattedMessage
        id='services-questions-p2'
        defaultMessage='A well-designed mobile app includes a user-friendly interface, smooth navigation, performance optimization, and compatibility across various devices. It also focuses on providing a seamless user experience and addressing specific user needs.'
      />}
      dataAos="fade-left"
      dataAosDelay="300"
    />

    <Accordion
      title={<FormattedMessage
        id='services-questions-3'
        defaultMessage='How does custom software differ from off-the-shelf solutions?'
      />}
      content={<FormattedMessage
        id='services-questions-p3'
        defaultMessage='Custom software is tailored to meet the specific needs and workflows of a business, offering unique features and integrations. Off-the-shelf solutions are generic and may not fully address individual business requirements or adapt to specific processes.'
      />}
      dataAos="fade-right"
      dataAosDelay="300"
    />

    <Accordion
      title={<FormattedMessage
        id='services-questions-4'
        defaultMessage='What are the benefits of integrating AI into business processes?'
      />}
      content={<FormattedMessage
        id='services-questions-p4'
        defaultMessage='AI can automate repetitive tasks, analyze large datasets for insights, personalize customer interactions, and enhance decision-making. It improves efficiency, reduces operational costs, and helps businesses stay competitive in a rapidly evolving market.'
      />}
      dataAos="fade-left"
      dataAosDelay="300"
    />

    <Accordion
      title={<FormattedMessage
        id='services-questions-5'
        defaultMessage='How do I choose the right package for my needs?'
      />}
      content={<FormattedMessage
        id='services-questions-p5'
        defaultMessage='Consider your business goals, required features, and budget when selecting a package. For simple needs, basic packages might suffice, while more complex requirements may necessitate standard or premium packages. Consulting with a provider can also help determine the best fit.'
      />}
      dataAos="fade-right"
      dataAosDelay="300"
    />

    <Accordion
      title={<FormattedMessage
        id='services-questions-6'
        defaultMessage='What is the typical timeline for delivering a web or mobile project?'
      />}
      content={<FormattedMessage
        id='services-questions-p6'
        defaultMessage='Project timelines vary based on complexity and requirements. Basic projects may take a few weeks, while more advanced or custom solutions can take several months. Clear communication with the development team helps ensure timely delivery and manage expectations.'
      />}
      dataAos="fade-left"
      dataAosDelay="300"
    />

  </div>
</section>


      </main>


      <ScrollToTop />

      <Footer />

    </div>
  )
}
export default Services;