import React from 'react';
import '../../pages/Service/ServicesPage.css'
import { Link } from 'react-router-dom';
import { ButtomGet } from '../ButtomGet/ButtomGet';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

const Service = () => (
    <section className="servicios" id="servicios">
        <h2 className="heading">
            <FormattedMessage
                id='services'
                defaultMessage='Services'
            />
        </h2>
        <div className="row">
           <div className="columns" data-aos="fade-up" data-aos-delay="200">
        <i className="fas fa-drafting-compass"></i>
        <h3>
            <FormattedMessage
                id='ui-ux'
                defaultMessage='UI/UX Design'
            />
        </h3>
        <p>
            <FormattedMessage
                id='ui-ux-info'
                defaultMessage='Design of intuitive and visually appealing interfaces for both web and mobile users, ensuring a seamless user experience that enhances the brand or product.'
            />
        </p>
           </div>
           <div className="columns" data-aos="fade-up" data-aos-delay="300">
               <i className="fas fa-laptop"></i>
               <h3>
                   <FormattedMessage
                       id='web-development'
                       defaultMessage='Web Development'
                   />
               </h3>
               <p>
                   <FormattedMessage
                       id='web-development-info'
                       defaultMessage='Creation of well-structured and responsive web pages with an attractive design, offering interactions that provide users with a satisfying browsing experience.'
                   />
               </p>
           </div>
           <div className="columns" data-aos="fade-up" data-aos-delay="400">
               <i className="fas fa-mobile-alt"></i>
               <h3>
                   <FormattedMessage
                       id='mobile-app'
                       defaultMessage='Mobile App Development'
                   />
               </h3>
               <p>
                   <FormattedMessage
                       id='mobile-app-info'
                       defaultMessage='Development of user-friendly mobile applications with responsive design and efficient functionality tailored to enhance the mobile user experience.'
                   />
               </p>
           </div>
           <div className="columns" data-aos="fade-up" data-aos-delay="500">
               <i className="fas fa-cogs"></i>
               <h3>
                   <FormattedMessage
                       id='software-customization'
                       defaultMessage='Software Customization'
                   />
               </h3>
               <p>
                   <FormattedMessage
                       id='software-customization-info'
                       defaultMessage='Tailored software solutions to meet the unique needs of your business, ensuring seamless integration with existing systems and enhanced productivity.'
                   />
               </p>
           </div>
           <div className="columns" data-aos="fade-up" data-aos-delay="600">
               <i className="fas fa-brain"></i>
               <h3>
                   <FormattedMessage
                       id='ai-machine-learning'
                       defaultMessage='AI & Machine Learning'
                   />
               </h3>
               <p>
                   <FormattedMessage
                       id='ai-machine-learning-info'
                       defaultMessage='Integration of AI and machine learning technologies to drive innovation, automate processes, and provide data-driven insights for better decision-making.'
                   />
               </p>
           </div>
           <div className="columns" data-aos="fade-up" data-aos-delay="700">
               <i className="fas fa-shield-alt"></i>
               <h3>
                   <FormattedMessage
                       id='security'
                       defaultMessage='Security Solutions'
                   />
               </h3>
               <p>
                   <FormattedMessage
                       id='security-info'
                       defaultMessage='Comprehensive security solutions to protect your digital assets, including data encryption, vulnerability assessments, and secure software development practices.'
                   />
               </p>
           </div>
       </div>

        <div className='portafolio-btn'>
            <Link to="/service">
                <ButtomGet/>
            </Link>
        </div>
    </section>
);

export default React.memo(Service);