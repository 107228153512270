import React from 'react';
import '../../pages/About/AboutPage.css'
import { Link } from 'react-router-dom';

import { ButtomGet } from '../ButtomGet/ButtomGet';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

const About = () => (
    <section className="sobre-mi" id="sobre-mi">
        <h2 className="heading">
            <FormattedMessage
                id='about'
                defaultMessage='About me'
            />
        </h2>

        <div className="row container">
            <div className="columns" data-aos="fade-right" data-aos-delay="300">
                <h3>
                    <FormattedMessage
                        id='im'
                        defaultMessage='who we are'
                    />
                </h3>
                <h4>
                    
                <div style={{ textAlign: 'center' }}>
                  <span style={{ fontWeight: 'bold', color: 'blue' }}>amara tech</span>
                </div>
                <br />
                <span style={{ fontWeight: 'bold', color: 'blue' }}>A</span>dvanced{' '}
                <span style={{ fontWeight: 'bold', color: 'blue' }}>M</span>obile{' '}
                <span style={{ fontWeight: 'bold', color: 'blue' }}>A</span>pplication{' '}
                 and{' '}
                <span style={{ fontWeight: 'bold', color: 'blue' }}>R</span>emote{' '}
                <span style={{ fontWeight: 'bold', color: 'blue' }}>A</span>ccess{' '}
                 and{' '}
                <span style={{ fontWeight: 'bold', color: 'blue' }}>tech</span>nology
                </h4>
                <p className='center-ab' style={{ fontWeight: 'bold'}}>
                    <FormattedMessage
                        id='my-description'
                        defaultMessage="We now available for solve your company's challenges with innovative mobile app, website, and custom software solutions.Our expert team turns your ideas into reality, enhancing performance and productivity with top-quality, professional service."
                    />
                </p>
                <ul>
                    <li>
                        <p className='center-ab'>
                            <span >
                                *{" "}
                            </span>
                            
                            <FormattedMessage
                                    id='li1'
                                    defaultMessage='Our team is about creating innovative solutions for your business.'
                                />
                        </p>
                    </li>
                    <li>
                        <p className='center-ab'>
                            <span >
                                *{" "}
                            </span>
                            
                            <FormattedMessage
                                    id='li2'
                                    defaultMessage='Our solutions are designed to help you adapt and thrive.'
                                />
                        </p>
                    </li>
                    <li>
                        <p className='center-ab'>
                            <span >
                                *{" "}
                            </span>
                            
                            <FormattedMessage
                                    id='li3'
                                    defaultMessage='Our mobile app is packed with features to streamline your workflow.'
                                />
                        </p>
                    </li>
                    
                </ul>
                <div className="mas-info">
                    <div className='mas-info-btn'>
                    <Link to="/about">
                        <ButtomGet/>
                    </Link>
                    </div>
                </div>
            </div>
            
                
            <div className="columns col-skill" data-aos="fade-left" data-aos-delay="650">
    <h3>Skills</h3>

    
    <h4>Front-End</h4>
    <div className="skill">
       
        <div>
            <img alt="HTML" className="icons-skils" src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg" />
            <h5>HTML</h5>
        </div>
        <div>
            <img alt="CSS" className="icons-skils" src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg" />
            <h5>CSS</h5>
        </div>
        <div>
            <img alt="Js" className="icons-skils" src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-plain.svg" />
            <h5>JavaScript</h5>
        </div>
        <div>
            <img alt="Sass" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg" />
            <h5>Sass</h5>
        </div>
        <div>
            <img alt="Bootstrap" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg" />
            <h5>Bootstrap</h5>
        </div>
        <div>
            <img alt="jQuery" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jquery/jquery-plain-wordmark.svg" />
            <h5>jQuery</h5>
        </div>
        <div>
            <img alt="React" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
            <h5>React</h5>
        </div>
        <div>
            <img alt="Typescript" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg" />
            <h5>Typescript</h5>
        </div>
        <div>
            <img alt="React Native" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
            <h5>React Native</h5>
        </div>
    </div>
     
    <h4>Mobile App Development</h4>
    <div className="skill">
       

        <div>
            <img alt="Flutter" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg" />
            <h5>Flutter</h5>
        </div>
        <div>
            <img alt="Android" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/android/android-original.svg" />
            <h5>Android</h5>
        </div>
        <div>
            <img alt="iOS" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/apple/apple-original.svg" />
            <h5>iOS</h5>
        </div>

    </div>

    
    <h4>Software Customization</h4>
    <div className="skill">
       
        <div>
            <img alt="WordPress" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/wordpress/wordpress-original.svg" />
            <h5>WordPress</h5>
        </div>
        
        <div>
            <img alt="Magento" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/magento/magento-original.svg" />
            <h5>Magento</h5>
        </div>
    </div>

   
    <h4>Back-End</h4>
    <div className="skill">
       
        <div>
            <img alt="Nodejs" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg" />
            <h5>NodeJs</h5>
        </div>
       
        <div>
            <img alt="php" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg" />
            <h5>PHP</h5>
        </div>
        
        <div>
            <img alt="mysql" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original-wordmark.svg" />
            <h5>MySQL</h5>
        </div>
        <div>
            <img alt="postgresql" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/postgresql/postgresql-original-wordmark.svg" />
            <h5>PostgreSQL</h5>
        </div>
        <div>
            <img alt="mongodb" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain-wordmark.svg" />
            <h5>MongodDB</h5>
        </div>
       
        <div>
            <img alt="C#" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg" />
            <h5>C#</h5>
        </div>
    </div>

 
    <h4>
        <FormattedMessage
            id='tools'
            defaultMessage='Tools'
        />
    </h4>
    <div className="skill">
       

        <div>
            <img alt="photoshop" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/photoshop/photoshop-plain.svg" title="Photoshop" />
            <h5>Photoshop</h5>
        </div>
        <div>
            <img alt="Visual Studio Code" className="icons-skils" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Visual_Studio_Code_1.18_icon.svg/1200px-Visual_Studio_Code_1.18_icon.svg.png" title="Visual Studio Code" />
            <h5>VS Code</h5>
        </div>
        <div>
            <img alt="git" className="icons-skils" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg" title="git" />
            <h5>Git</h5>
        </div>
    </div>


</div>

        </div>
    </section>
);

export default React.memo(About);